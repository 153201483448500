import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TextPage from '../components/text-page';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const OrganizationalChart = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const images = useStaticQuery(graphql`{
    file(relativePath: {eq: "oc_en.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`);

  return (
    <>
      <TextPage locale={intl.locale} title={`${messages.organizational_chart}`} otherElements={
        <div className="ui centered two column grid">
          <div className="column">
            <GatsbyImage image={images.file.childImageSharp.gatsbyImageData} alt="Organizational Chart"/>
          </div>
        </div>
      }/>
    </>
  );
};

export default OrganizationalChart;